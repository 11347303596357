<template>
  <div
    :class="grid"
    class="k-field-wrapper"
  >
    <v-label v-if="translatedLabel">
      {{ translatedLabel }}
    </v-label>
    <v-btn-toggle
      tile
      multiple
      dense
      color="primary"
      v-bind="fieldProps"
      v-on="$listeners"
    >
      <v-btn
        v-for="i in 7"
        :key="i"
        :disabled="disabled"
        small
        :value="i"
      >
        {{ $tc(`global.shortWeekDays.${i}`) }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KWeekdayField',
  mixins: [Field],
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
