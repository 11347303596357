<template>
  <v-row no-gutters>
    <v-col>
      <h6
        class="primary--text text-h6 font-weight-regular mb-6"
      >
        {{ $t('priceAgreement.periods.tabs.purchase') }}
      </h6>
      <v-tooltip
        top
        :disabled="values.purchaseAgreementTypeIdIsEditable"
      >
        <template #activator="{ on }">
          <div
            style="width: max-content"
            v-on="on"
          >
            <KRadioGroup
              v-model="values.purchaseAgreement.purchaseAgreementTypeId"
              :items="purchaseAgreementTypes"
              field="purchaseAgreement.purchaseAgreementTypeId"
              :disabled="!values.purchaseAgreementTypeIdIsEditable"
              row
            />
          </div>
        </template>
        <span> {{ $t('priceAgreement.periods.messages.priceAgreementLinkedToPlacement') }} </span>
      </v-tooltip>
    
      <actionable-alert
        v-if="values.purchaseAgreement.purchaseAgreementTypeId === purchaseAgreementType.ACCORDING_TO_CLA && purchaseAgreementTypeFailureReasons.length"
        :message="$t('actionables.messages.priceAgreementActionableFailed')"
        :failure-reasons="purchaseAgreementTypeFailureReasons"
      />
      <KFigureField
        v-if="values.purchaseAgreement.purchaseAgreementTypeId === purchaseAgreementType.FIXED_RATE"
        v-model="values.purchaseAgreement.rate"
        field="purchaseAgreement.rate"
        grid="col-md-2"
        step="0.01"
        prefix="€"
      />
      <h6
        class="primary--text text-h6 font-weight-regular mb-6 mt-10"
      >
        {{ $t('priceAgreement.periods.sections.purchasePremium') }}
      </h6>
      <KRadioGroup
        v-model="values.premiumAgreement.premiumAgreementTypeId"
        :items="premiumAgreementTypes"
        field="premiumAgreement.premiumAgreementTypeId"
        :rules="[premiumNotFilled]"
        validate-on-blur
        row
      />
      <AgreementTable
        v-if="values.premiumAgreement.premiumAgreementTypeId !== premiumAgreementType.NO_PREMIUM"
        v-model="values.premiumAgreement.grid"
        class="mb-6"
        :disabled="disabled"
        :base-rate="parseFloat(values.purchaseAgreement.rate)"
        :use-percentages="values.premiumAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE"
      />
      <AgreementGrid
        v-if="values.premiumAgreement.premiumAgreementTypeId !== premiumAgreementType.NO_PREMIUM"
        :agreements="values.premiumAgreement.grid"
        :fallback-amount="values.premiumAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE ? 100 : 0"
        :use-percentages="values.premiumAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE"
      />
    </v-col>
  </v-row>
</template>

<script>
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import { premiumAgreementType } from '@/application/enums/premiumAgreementType.js';
import { purchaseAgreementType } from '@/application/enums/purchaseAgreementType.js';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import FormTab from '@/application/mixins/FormTab.vue';
import AgreementGrid from '@/modules/priceAgreements/components/AgreementGrid.vue';
import AgreementTable from '@/modules/priceAgreements/components/AgreementTable.vue';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';
import { actionableHasNoCollectiveAgreement } from '@/modules/actionable/api';

export default {
  name: 'PurchaseAgreementTab',
  components: {
    ActionableAlert,
    KFigureField,
    KRadioGroup,
    AgreementTable,
    AgreementGrid,
  },
  mixins: [FormTab],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      purchaseAgreementTypeFailureReasons: [],
    };
  },
  computed: {
    purchaseAgreementType: () => purchaseAgreementType,
    purchaseAgreementTypes() {
      return Object.keys(purchaseAgreementType).filter(type => {
        // zzp priceagreement does not have the CLA option
        return this.values.priceAgreementTypeId === 1 ||
            (this.values.priceAgreementTypeId === 2 && type !== 'ACCORDING_TO_CLA');
      }).map(type => {
        return {
          value: purchaseAgreementType[type],
          text: this.$t(`priceAgreement.purchaseAgreementType.${type}`),
        };
      });
    },
    premiumAgreementType: () => premiumAgreementType,
    premiumAgreementTypes() {
      return Object.keys(premiumAgreementType).map(type => {
        return {
          value: premiumAgreementType[type],
          text: this.$t(`priceAgreement.premiumAgreementType.${type}`),
        };
      });
    },
  },
  watch: {
    'values.purchaseAgreement.purchaseAgreementTypeId'() {
      this.checkHasNoCollectiveAgreement();
    },
  },
  mounted() {
    this.checkHasNoCollectiveAgreement();
  },
  methods: {
    async checkHasNoCollectiveAgreement() {
      if (this.values.priceAgreementTypeId !== priceAgreementType.TEMPORARY_WORKER) return;
      if (this.values.purchaseAgreement.purchaseAgreementTypeId !== purchaseAgreementType.ACCORDING_TO_CLA) return;

      const response = await actionableHasNoCollectiveAgreement(this.$route.params.employerId);
      this.purchaseAgreementTypeFailureReasons = response.data.reasons;
    },
    premiumNotFilled(val) {
      return val === premiumAgreementType.NO_PREMIUM || !!this.values.premiumAgreement.grid.length || this.$t('priceAgreement.periods.messages.premiumNotFilled');
    },
  },
};
</script>
