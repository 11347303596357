<template>
  <div>
    <KRadioGroup
      v-model="values.salesAgreement.temporaryWorkerSalesAgreementTypeId"
      row
      field="salesAgreement.temporaryWorkerSalesAgreementTypeId"
      :items="temporaryWorkerSalesAgreementTypes"
    />
    <template v-if="values.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.CONVERSION_FACTOR">
      <KTextField
        v-model="values.salesAgreement.amount"
        grid="col-md-2"
        validation-field="salesAgreement.amount"
        required
        type="number"
        field="salesAgreement.factor"
      />
      <KRadioGroup
        v-model="values.salesAgreement.allowanceTypeId"
        field="salesAgreement.allowanceTypeId"
        :items="allowanceTypes"
        required
      />
      <KTextField
        v-if="values.salesAgreement.allowanceTypeId === allowanceType.ALLOWANCE_TYPE_CUSTOM"
        v-model="values.salesAgreement.allowanceAmount"
        grid="col-md-2"
        required
        type="number"
        field="salesAgreement.allowanceAmount"
      />
    </template>
    <template v-if="values.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.FIXED_INCREMENT">
      <v-row no-gutters>
        <KTextField
          v-model="values.salesAgreement.amount"
          grid="col-md-2"
          validation-field="salesAgreement.amount"
          required
          type="number"
          prefix="€"
          field="salesAgreement.defaultIncrement"
        />
        <KTextField
          v-model="values.salesAgreement.temporaryWorkerCostPriceFactor"
          grid="col-md-2"
          class="ml-4"
          validation-field="salesAgreement.amount"
          required
          type="number"
          field="salesAgreement.temporaryWorkerCostPriceFactor"
        />
      </v-row>
    </template>
    <template v-if="values.salesAgreement.temporaryWorkerSalesAgreementTypeId === temporaryWorkerSalesAgreementType.SALES_RATE">
      <KTextField
        v-model="values.salesAgreement.amount"
        grid="col-md-2"
        validation-field="salesAgreement.amount"
        required
        type="number"
        prefix="€"
        field="salesAgreement.defaultAmount"
      />
    </template>

    <template v-if="showPremiumAgreementType">
      <h6 class="primary--text text-h6 font-weight-regular mb-6 mt-10">
        {{ $t('priceAgreement.periods.sections.salesPremiumUZK') }}
      </h6>
      <KRadioGroup
        v-model="values.salesAgreement.premiumAgreementTypeId"
        :items="premiumAgreementTypes"
        field="salesAgreement.premiumAgreementTypeId"
        row
      />
      <div v-if="values.salesAgreement.premiumAgreementTypeId !== premiumAgreementType.NO_PREMIUM">
        <AgreementTable
          ref="salesAgreementTable"
          v-model="values.salesAgreement.grid"
          show-copy-button
          :disabled="disabled"
          class="mb-6"
          :base-rate="parseFloat(values.salesAgreement.amount)"
          :use-percentages="values.salesAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE"
          @copy-grid="copyPremiumAgreementGrid"
        />
        <AgreementGrid
          :agreements="values.salesAgreement.grid"
          :fallback-amount="values.salesAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE ? 100 : 0"
          :use-percentages="values.salesAgreement.premiumAgreementTypeId === premiumAgreementType.PERCENTAGE"
        />
      </div>
    </template>
  </div>
</template>

<script>
import FormTab from '@/application/mixins/FormTab.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import allowanceType from '@/application/enums/allowanceType.js';
import temporaryWorkerSalesAgreementType from '@/application/enums/temporaryWorkerSalesAgreementType.js';
import { premiumAgreementType } from '@/application/enums/premiumAgreementType.js';
import AgreementGrid from '@/modules/priceAgreements/components/AgreementGrid.vue';
import AgreementTable from '@/modules/priceAgreements/components/AgreementTable.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'TemporaryWorkerSalesAgreementTab',
  components: {
    KTextField,
    AgreementGrid,
    AgreementTable,
    KRadioGroup,
  },
  mixins: [FormTab],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allowanceType: () => allowanceType,
    showPremiumAgreementType() {
      return [temporaryWorkerSalesAgreementType.SALES_RATE, temporaryWorkerSalesAgreementType.FIXED_INCREMENT].includes(this.values.salesAgreement.temporaryWorkerSalesAgreementTypeId);
    },
    temporaryWorkerSalesAgreementType: () => temporaryWorkerSalesAgreementType,
    temporaryWorkerSalesAgreementTypes() {
      return Object.keys(temporaryWorkerSalesAgreementType).map(type => {
        return {
          value: temporaryWorkerSalesAgreementType[type],
          text: this.$t(`priceAgreement.temporaryWorkerSalesAgreementType.${type}`),
        };
      });
    },
    premiumAgreementType: () => premiumAgreementType,
    premiumAgreementTypes() {
      return Object.keys(premiumAgreementType).reverse().map(type => {
        return {
          value: premiumAgreementType[type],
          text: this.$t(`priceAgreement.premiumAgreementType.${type}`),
        };
      });
    },
    allowanceTypes() {
      return Object.keys(allowanceType)
        .filter(type => type !== 'ALLOWANCE_TYPE_NONE')
        .map(type => ({
          value: allowanceType[type],
          text: this.$t(`priceAgreement.allowanceType.${type}`),
        }));
    },
  },
  watch: {
    showPremiumAgreementType(val){
      if (!val || this.values.salesAgreement.premiumAgreementTypeId) return;
      this.values.salesAgreement.premiumAgreementTypeId = 3;
    },
    'values.salesAgreement.temporaryWorkerSalesAgreementTypeId'(typeId) {
      if (this.values.salesAgreement.temporaryWorkerCostPriceFactor || typeId !== temporaryWorkerSalesAgreementType.FIXED_INCREMENT) return;
      this.setTemporaryWorkerCostPriceFactor();
    },
  },
  methods: {
    copyPremiumAgreementGrid() {
      this.values.salesAgreement.premiumAgreementTypeId = this.values.premiumAgreement.premiumAgreementTypeId;
      this.values.premiumAgreement.grid.forEach(gridLine => {
        const line = cloneDeep(gridLine);
        this.$refs.salesAgreementTable.addAgreement(line);
      });
    },
    setTemporaryWorkerCostPriceFactor() {
      if (!this.values.salesAgreement.tradeNames[0]?.temporaryWorkerCostPriceFactor || this.values.salesAgreement.tradeNames.length !== 1) return;
      this.values.salesAgreement.temporaryWorkerCostPriceFactor = this.values.salesAgreement.tradeNames[0]?.temporaryWorkerCostPriceFactor;
    },
  },
};
</script>
