<template>
  <div id="page-priceAgreements-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('priceAgreement.periods.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="openCreate"
        >
          {{ $t('actions.createResource', { resource: $tc('priceAgreement.periods.title', 1) }) }}
        </v-btn>
        <PriceAgreementPeriodForm
          ref="createForm"
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('priceAgreement.periods.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="priceAgreement.periods.fields"
          resource="priceAgreement"
          :can-delete="canDelete"
          @click:edit="openUpdate"
          @click:copy="handleOpenCopyDialog"
          @click:delete="deletePriceAgreementPeriod"
        >
          <template #item.isActive="{ item }">
            <v-icon
              v-if="item.isActive"
              color="success"
            >
              $circle
            </v-icon>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <PriceAgreementPeriodForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('priceAgreement.periods.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, remove, update } from '@/modules/priceAgreements/api/priceAgreementPeriods.js';
import { show as priceAgreementShow } from '@/modules/priceAgreements/api/priceAgreement.js';
import { purchaseAgreementType } from '@/application/enums/purchaseAgreementType.js';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import PriceAgreementPeriod from '@/application/models/PriceAgreementPeriod.js';
import PriceAgreementPeriodForm from '@/modules/priceAgreements/components/PriceAgreementPeriodForm.vue';
import { mapGetters } from 'vuex';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'AgreementsTable',
  components: {
    PriceAgreementPeriodForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    employer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new PriceAgreementPeriod(),
      createFormValues: new PriceAgreementPeriod(),
    };
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    crudHeaders() {
      return [
        {
          width: '100',
          value: 'isActive',
          language: 'isActive',
          cellClass: 'no-table-separator',
        },
        {
          value: 'from',
          language: 'from',
        },
      ];
    },
  },
  watch: {
    '$route.params.openCreateDialog': {
      handler() {
        this.$nextTick(() => {
          if (this.$route.params.openCreateDialog) {
            this.openCreate();
          }
        });
      },
      immediate: true,
    },
  },
  async created() {
    const response = await  priceAgreementShow(this.$route.params.priceAgreementId);
    const priceAgreement = response.data.data;
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement' },
        text: this.$tc('priceAgreement.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement.show', params: { priceAgreementId: priceAgreement.id } },
        text: priceAgreement.name,
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement.periods' },
        text: this.$tc('priceAgreement.periods.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.$route.params.priceAgreementId, this.createFormValues);
    },
    canDelete(item) {
      return item.isDeletable;
    },
    async deletePriceAgreementPeriod(priceAgreementPeriod) {
      try {
        eventBus.$emit('confirm', {
          title: this.$t('actions.deleteConfirmation.title'),
          body: this.$t('actions.deleteConfirmation.bodyAlt', {
            resource: this.$tc('priceAgreement.periods.title', 1).toLowerCase(),
          }),
          confirmCallback: async () => {
            await remove(priceAgreementPeriod.id);
            this.$refs.table.reload();
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('actions.deleteResource', { resource: this.$tc('priceAgreement.periods.title', 1) }),
            });
          },
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
        throw error;
      }
    },
    async indexRequest(...args) {
      const request = await index(this.$route.params.priceAgreementId, ...args);
      if (request.data.data) {
        request.data.data.map(shift => {
          shift.from = shift.from ? dayjs(shift.from).format('LL') : '';
        });
      }
      return request;
    },
    async openUpdate(item) {
      this.updateFormValues = new PriceAgreementPeriod();
      const priceAgreementShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(priceAgreementShowRequest.data.data);
      this.updateDialog = true;
    },
    async openCreate() {
      this.createFormValues = new PriceAgreementPeriod();
      const priceAgreementShowRequest = await priceAgreementShow(this.$route.params.priceAgreementId);
      this.createFormValues.priceAgreementTypeId = priceAgreementShowRequest.data.data.priceAgreementTypeId;

      switch (this.createFormValues.priceAgreementTypeId) {
      case priceAgreementType.TEMPORARY_WORKER:
        this.createFormValues.purchaseAgreement.purchaseAgreementTypeId = purchaseAgreementType.ACCORDING_TO_CLA;
        break;
      case priceAgreementType.SELF_EMPLOYED:
        this.createFormValues.purchaseAgreement.purchaseAgreementTypeId = purchaseAgreementType.FIXED_RATE;
        break;
      }

      this.createDialog = true;
    },
    getPriceAgreementTypeTranslation(id) {
      return this.$t(`priceAgreement.priceAgreementType.${Object.keys(priceAgreementType)
        .find(key => priceAgreementType[key] === id)}`);
    },
    async handleOpenCopyDialog(item) {
      this.createFormValues = new PriceAgreementPeriod();
      const { data: { data } } = await show(item.id);
      this.createFormValues.mapResponse(data);

      // Because it is a copy, it should not have a primary-key
      this.createFormValues.id = null;
      this.createDialog = true;
    },
  },
};
</script>
