<script>
export default {
  name: 'FormTab',
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    values: {
      set(newValue) {
        this.$emit('input', newValue);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>
