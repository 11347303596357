<template>
  <div>
    <KRadioGroup
      v-model="values.salesAgreement.selfEmployedSalesAgreementTypeId"
      row
      field="salesAgreement.selfEmployedSalesAgreementTypeId"
      :items="selfEmployedSalesAgreementTypes"
    />
    <KRadioGroup
      v-model="values.salesAgreement.selfEmployedSalesAgreementRateTypeId"
      row
      field="salesAgreement.selfEmployedSalesAgreementRateTypeId"
      :items="selfEmployedSalesAgreementRateTypes"
    />
    <KTextField
      v-if="values.salesAgreement.selfEmployedSalesAgreementRateTypeId !== selfEmployedSalesAgreementRateType.MANUAL_INPUT"
      v-model="values.salesAgreement.amount"
      grid="col-md-2"
      validation-field="salesAgreement.amount"
      type="number"
      :suffix="values.salesAgreement.selfEmployedSalesAgreementRateTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE ? '%' : ''"
      :prefix="values.salesAgreement.selfEmployedSalesAgreementRateTypeId !== selfEmployedSalesAgreementRateType.PERCENTAGE ? '€' : ''"
      :field="`salesAgreement.${amountFieldName}`"
    />
    <h6
      v-if="values.salesAgreement.selfEmployedSalesAgreementRateTypeId !== selfEmployedSalesAgreementRateType.MANUAL_INPUT"
      class="primary--text text-h6 font-weight-regular mb-6 mt-10"
    >
      {{ $t('priceAgreement.periods.sections.salesPremiumZZP') }}
    </h6>
    <v-radio-group
      v-if="agreementRateIsPercentage"
      v-model="values.salesAgreement.allowanceTypeId"
      label=""
      :rules="[isRequired(values.salesAgreement.allowanceTypeId, $t('priceAgreement.periods.fields.salesAgreement.allowanceTypeId'))]"
      :items="allowanceTypes"
    >
      <v-radio
        :value="allowanceType.ALLOWANCE_TYPE_CUSTOM"
        class="py-1"
      >
        <template #label>
          <KTextField
            v-model="values.salesAgreement.allowanceAmount"
            grid="col-md-2"
            dense
            hide-details
            suffix="%"
            type="number"
            label=""
            :required="values.salesAgreement.allowanceTypeId === allowanceType.ALLOWANCE_TYPE_CUSTOM"
            hide-required-asterisk
          />
        </template>
      </v-radio>
      <v-radio
        :value="allowanceType.ALLOWANCE_TYPE_NONE"
        class="py-1"
      >
        <template #label>
          {{ $t('priceAgreement.allowanceTypeId.ALLOWANCE_TYPE_NONE') }}
        </template>
      </v-radio>
    </v-radio-group>

    <KRadioGroup
      v-if="agreementRateIsFixed || agreementRateIsSalesRate"
      v-model="values.salesAgreement.premiumAgreementTypeId"
      :items="premiumAgreementTypes"
      field="salesAgreement.premiumAgreementTypeId"
      :rules="[premiumNotFilled]"
      row
    />
    <div v-if="(agreementRateIsFixed || agreementRateIsSalesRate) && values.salesAgreement.premiumAgreementTypeId !== selfEmployedSalesAgreementRateType.SALES_RATE">
      <AgreementTable
        ref="selfEmployedSalesAgreementTable"
        v-model="values.salesAgreement.grid"
        class="mb-6"
        show-copy-button
        :disabled="disabled"
        :base-rate="parseFloat(values.salesAgreement.amount)"
        :use-percentages="values.salesAgreement.premiumAgreementTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE"
        @copy-grid="copyPremiumAgreementGrid"
      />
      <AgreementGrid
        :agreements="values.salesAgreement.grid"
        :fallback-amount="values.salesAgreement.premiumAgreementTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE ? 100 : 0"
        :use-percentages="values.salesAgreement.premiumAgreementTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE"
      />
    </div>
  </div>
</template>

<script>
import FormTab from '@/application/mixins/FormTab.vue';
import selfEmployedSalesAgreementType from '@/application/enums/selfEmployedSalesAgreementType.js';
import selfEmployedSalesAgreementRateType from '@/application/enums/selfEmployedSalesAgreementRateType.js';
import { premiumAgreementType } from '@/application/enums/premiumAgreementType.js';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import AgreementTable from '@/modules/priceAgreements/components/AgreementTable.vue';
import AgreementGrid from '@/modules/priceAgreements/components/AgreementGrid.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import allowanceType from '@/application/enums/allowanceType.js';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'SelfEmployedSalesAgreementTab',
  components: {
    KTextField,
    AgreementGrid,
    AgreementTable,
    KRadioGroup,
  },
  mixins: [FormTab],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    agreementRateIsPercentage() {
      return this.values.salesAgreement.selfEmployedSalesAgreementRateTypeId === selfEmployedSalesAgreementRateType.PERCENTAGE;
    },
    agreementRateIsFixed() {
      return this.values.salesAgreement.selfEmployedSalesAgreementRateTypeId === selfEmployedSalesAgreementRateType.FIXED_INCREMENT;
    },
    agreementRateIsSalesRate() {
      return this.values.salesAgreement.selfEmployedSalesAgreementRateTypeId === selfEmployedSalesAgreementRateType.SALES_RATE;
    },
    showPremiumAgreementType() {
      return [selfEmployedSalesAgreementRateType.SALES_RATE, selfEmployedSalesAgreementRateType.FIXED_INCREMENT]
        .includes(this.values.salesAgreement.selfEmployedSalesAgreementRateTypeId);
    },
    selfEmployedSalesAgreementRateType() {
      return selfEmployedSalesAgreementRateType;
    },
    selfEmployedSalesAgreementTypes() {
      return Object.keys(selfEmployedSalesAgreementType).map(type => {
        return {
          value: selfEmployedSalesAgreementType[type],
          text: this.$t(`priceAgreement.selfEmployedSalesAgreementType.${type}`),
        };
      });
    },
    amountFieldName() {
      switch (this.values.salesAgreement.selfEmployedSalesAgreementRateTypeId) {
      case selfEmployedSalesAgreementRateType.PERCENTAGE:
        return 'percentage';
      case selfEmployedSalesAgreementRateType.SALES_RATE:
        return 'defaultAmount';
      case selfEmployedSalesAgreementRateType.FIXED_INCREMENT:
      default:
        return 'defaultIncrement';
      }
    },
    selfEmployedSalesAgreementRateTypes() {
      return Object.entries(selfEmployedSalesAgreementRateType)
        .map(([
          type,
        ]) => {
          if (type === 'SALES_RATE') {
            return {
              value: selfEmployedSalesAgreementRateType[type],
              text: this.$tc(`priceAgreement.selfEmployedSalesAgreementRateType.${type}`, this.values.salesAgreement.selfEmployedSalesAgreementTypeId),
            };
          }
          return {
            value: selfEmployedSalesAgreementRateType[type],
            text: this.$t(`priceAgreement.selfEmployedSalesAgreementRateType.${type}`),
          };
        });
    },
    allowanceType: () => allowanceType,
    allowanceTypes() {
      return Object.keys(allowanceType).map(type => ({
        value: allowanceType[type],
        text: this.$t(`priceAgreement.allowanceType.${type}`),
      }));
    },
    premiumAgreementType: () => premiumAgreementType,
    premiumAgreementTypes() {
      return Object.keys(premiumAgreementType).reverse().map(type => {
        return {
          value: premiumAgreementType[type],
          text: this.$t(`priceAgreement.premiumAgreementType.${type}`),
        };
      });
    },
  },
  methods: {
    copyPremiumAgreementGrid() {
      this.values.salesAgreement.premiumAgreementTypeId = this.values.premiumAgreement.premiumAgreementTypeId;
      this.values.premiumAgreement.grid.forEach(gridLine => {
        const line = cloneDeep(gridLine);
        this.$refs.selfEmployedSalesAgreementTable.addAgreement(line);
      });
    },
    premiumNotFilled(val) {
      return val === premiumAgreementType.NO_PREMIUM || !!this.values.salesAgreement.grid.length || this.$t('priceAgreement.periods.messages.premiumNotFilled');
    },
    isRequired(val, field) {
      return !!val || this.$t('validation.required', { field });
    },
  },
};
</script>
