<template>
  <k-field-group language-prefix="priceAgreement.periods.fields">
    <k-form-dialog
      hide-svg
      v-bind="$attrs"
      :panels="panels"
      :disabled="isDisabled"
      v-on="$listeners"
    >
      <template #alert>
        <v-alert
          v-model="isDisabled"
          type="warning"
          outlined
        >
          {{ $t('priceAgreement.messages.priceAgreementUpdateDisabled') }}
        </v-alert>
      </template>
      <template #panel.0>
        <v-col md="4">
          <KDateField
            v-model="values.from"
            field="from"
            grid="col-6"
            :disabled="isDisabled"
            required
          />
        </v-col>
      </template>
      <template #panel.1>
        <PurchaseAgreementTab
          v-model="values"
          :disabled="isDisabled"
        />
      </template>
      <template #panel.2>
        <SalesAgreementTab
          v-model="values"
          :disabled="isDisabled"
        />
      </template>
      <template #panel.3>
        <CompensationTab
          v-model="values"
          :disabled="isDisabled"
        />
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import PurchaseAgreementTab from '@/modules/priceAgreements/components/formTabs/PurchaseAgreementTab.vue';
import SalesAgreementTab from '@/modules/priceAgreements/components/formTabs/SalesAgreementTab.vue';
import CompensationTab from '@/modules/priceAgreements/components/formTabs/CompensationTab.vue';

export default {
  name: 'PriceAgreementPeriodForm',
  components: {
    CompensationTab,
    SalesAgreementTab,
    PurchaseAgreementTab,
    KDateField,
    KFieldGroup,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled(){
      return this.isUpdateForm && !this.values.isEditable;
    },
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
        { name: this.$t('priceAgreement.periods.tabs.purchase') },
        { name: this.$t('priceAgreement.periods.tabs.sales') },
        { name: this.$t('priceAgreement.periods.tabs.compensation') },
      ];
    },
  },
};
</script>
