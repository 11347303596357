import { get, post, put, destroy } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (priceAgreementId, ...args) => getPaginated(`price-agreement/${priceAgreementId}/detail`, ...args);

export const create = (priceAgreementId, priceAgreementPeriod) => post(`price-agreement/${priceAgreementId}/detail`, priceAgreementPeriod);

export const show = (priceAgreementPeriodId) => get(`price-agreement-detail/${priceAgreementPeriodId}`);

export const update = (priceAgreementPeriod) => put(`price-agreement-detail/${priceAgreementPeriod.id}`, priceAgreementPeriod);

export const remove = (priceAgreementPeriodId) => destroy(`price-agreement-detail/${priceAgreementPeriodId}`);
