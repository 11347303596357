<template>
  <k-field-group language-prefix="priceAgreement.periods.fields">
    <KFigureField
      v-model="values.purchaseAgreement.commutingKilometerAllowance"
      grid="col-md-3"
      prefix="€"
      :step="'0.01'"
      min="0"
      field="purchaseAgreement.commutingKilometerAllowance"
    />
    <KFigureField
      v-model="values.purchaseAgreement.businessKilometerAllowance"
      grid="col-md-3"
      prefix="€"
      :step="'0.01'"
      min="0"
      field="purchaseAgreement.businessKilometerAllowance"
    />
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import FormTab from '@/application/mixins/FormTab.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';

export default {
  name: 'CompensationTab',
  components: {
    KFigureField,
    KFieldGroup,
  },
  mixins: [FormTab],
};
</script>
