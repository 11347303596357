<template>
  <div>
    <div
      v-if="showCopyButton && !disabled"
      class="d-flex"
    >
      <v-spacer />
      <k-btn
        small
        @click="$emit('copy-grid')"
      >
        {{ $t('priceAgreement.grid.copy') }}
      </k-btn>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('priceAgreement.grid.fields.days') }}
          </th>
          <th class="text-left">
            {{ $t('priceAgreement.grid.fields.from') }}
          </th>
          <th class="text-left">
            {{ $t('priceAgreement.grid.fields.to') }}
          </th>
          <th class="text-left">
            {{ $t(`priceAgreement.grid.fields.${usePercentages ? 'percentage' : 'amount'}`) }}
          </th>
          <th
            class="text-left"
            style="min-width:150px;"
          >
            {{ $t(`priceAgreement.grid.fields.name`) }}
          </th>
          <th class="text-left" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(agreement, index) in agreements"
          :key="index"
        >
          <td>
            <div>
              <KWeekdayField
                v-model="agreement.days"
                class="d-inline mr-3"
                :disabled="disabled"
                label=""
              />
              <v-btn-toggle
                v-model="agreement.festiveDay"
                color="primary"
                tile
              >
                <v-btn
                  small
                  :disabled="disabled"
                >
                  {{ $t('priceAgreement.grid.fields.festiveDay') }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </td>
          <td class="pt-2">
            <KTimeField
              v-model="agreement.from"
              :disabled="disabled"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <KTimeField
              v-model="agreement.to"
              :disabled="disabled"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <KTextField
              v-model.number="agreement.amount"
              type="number"
              dense
              label=""
              :hint="showSum(agreement.amount)"
              :prefix="usePercentages ? '%' : '€'"
            />
          </td>
          <td class="pt-2">
            <KTextField
              v-model="agreement.name"
              type="text"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <v-btn
              icon
              :disabled="disabled"
              @click="deleteAgreement(index)"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="!disabled">
        <tr>
          <td>
            <div>
              <KWeekdayField
                v-model="newAgreement.days"
                class="d-inline mr-3"
                label=""
              />
              <v-btn-toggle
                v-model="newAgreement.festiveDay"
                color="primary"
                tile
              >
                <v-btn small>
                  {{ $t('priceAgreement.grid.fields.festiveDay') }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </td>
          <td class="pt-2">
            <KTimeField
              v-model="newAgreement.from"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <KTimeField
              v-model="newAgreement.to"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <KTextField
              v-model.number="newAgreement.amount"
              type="number"
              dense
              label=""
              :hint="showSum(newAgreement.amount)"
              :prefix="usePercentages ? '%' : '€'"
            />
          </td>
          <td class="pt-2">
            <KTextField
              v-model="newAgreement.name"
              type="text"
              dense
              label=""
            />
          </td>
          <td class="pt-2">
            <v-btn
              small
              color="primary"
              depressed
              tile
              :disabled="!newAgreementIsValid"
              @click="addAgreement()"
            >
              {{ $t('actions.create') }}
            </v-btn>
          </td>
        </tr>
      </tfoot>
    </v-simple-table>
  </div>
</template>

<script>
import KWeekdayField from '@/components/crud/fields/KWeekdayField.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'AgreementTable',
  components: {
    KBtn,
    KTextField,
    KTimeField,
    KWeekdayField,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    usePercentages: {
      type: Boolean,
      default: false,
    },
    showCopyButton: {
      type: Boolean,
      default: false,
    },
    baseRate: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    agreements: {},
    newAgreement: {},
  }),
  computed: {
    newAgreementIsValid() {
      return (this.newAgreement.days?.length > 0 || typeof this.newAgreement.festiveDay !== 'undefined') &&
          this.newAgreement.from &&
          this.newAgreement.to &&
          this.newAgreement.amount &&
          this.newAgreement.name;
    },
  },
  watch: {
    agreements: {
      deep: true,
      handler() {
        const agreements = this.agreements.map((agreement) => {

          return {
            ...agreement,
            days: [...agreement.days || [], ...(typeof agreement.festiveDay !== 'undefined') ? [0] : []],
          };
        });
        this.$emit('input', agreements);
      },
    },
  },
  created() {
    this.agreements = this.value.map((agreement) => {
      return {
        ...agreement,
        days: agreement.days.filter((day) => day !== 0),
        festiveDay: agreement.days.includes(0) ? 0 : undefined,
      };
    });
  },
  methods: {
    showSum(amountToAdd) {
      if (!this.baseRate || !amountToAdd) return '';

      if (this.usePercentages) {
        return `€${this.baseRate.toFixed(2)} × ${amountToAdd}% = €${(this.baseRate * (amountToAdd / 100)).toFixed(2)}`;
      }

      return `€${this.baseRate.toFixed(2)} + €${amountToAdd.toFixed(2)} = €${(this.baseRate + amountToAdd).toFixed(2)}`;
    },
    deleteAgreement(index) {
      this.$delete(this.agreements, index);
    },
    addAgreement(agreement) {
      this.agreements.push(agreement || this.newAgreement);
      this.newAgreement = {};
    },
  },
};
</script>
