<template>
  <k-field-group language-prefix="priceAgreement.periods.fields">
    <h6
      class="primary--text text-h6 font-weight-regular mb-6"
    >
      {{ $t('priceAgreement.periods.tabs.sales') }}
    </h6>
    <TradeNameAutocomplete
      v-model="values.salesAgreement.tradeNames"
      grid="col-md-2"
      field="salesAgreement.tradeNameIds"
      :multiple="true"
      :trade-names="tradeNames"
      required
      @blur="$refs.temporaryWorkerSalesAgreementTab?.setTemporaryWorkerCostPriceFactor()"
    />
    <div v-if="isSelfEmployed">
      <SelfEmployedSalesAgreementTab
        v-model="values"
        v-bind="$attrs"
      />
    </div>
    <div v-else-if="isTemporaryWorker">
      <TemporaryWorkerSalesAgreementTab
        ref="temporaryWorkerSalesAgreementTab"
        v-model="values"
        v-bind="$attrs"
      />
    </div>
  </k-field-group>
</template>

<script>
import FormTab from '@/application/mixins/FormTab.vue';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import SelfEmployedSalesAgreementTab
  from '@/modules/priceAgreements/components/formTabs/SelfEmployedSalesAgreementTab.vue';
import TemporaryWorkerSalesAgreementTab
  from '@/modules/priceAgreements/components/formTabs/TemporaryWorkerSalesAgreementTab.vue';
import TradeNameAutocomplete from '@/modules/companyEntity/components/TradeNameAutocomplete.vue';
import { intermediaryTradeNames } from '@/modules/companyEntity/api/tradeNameAutocomplete.ts';

export default {
  name: 'SalesAgreementTab',
  components: {
    TradeNameAutocomplete,
    TemporaryWorkerSalesAgreementTab,
    SelfEmployedSalesAgreementTab,
    KFieldGroup,
  },
  mixins: [FormTab],
  data() {
    return {
      tradeNames: [],
    };
  },
  computed: {
    isSelfEmployed() {
      return this.values.priceAgreementTypeId === priceAgreementType.SELF_EMPLOYED;
    },
    isTemporaryWorker() {
      return this.values.priceAgreementTypeId === priceAgreementType.TEMPORARY_WORKER;
    },
  },
  created() {
    this.setTradeNames();
  },
  methods: {
    async setTradeNames() {
      const response = await intermediaryTradeNames();
      this.tradeNames = response.data.data;
    },
  },
};
</script>
